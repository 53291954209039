import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';

const Countdown = ({ targetDate }) => {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60)
            };
        }

        return timeLeft;
    }

    const tiemops = [
        {
            label: timeLeft.days === 1 ? "Día" : "Días",
            value: timeLeft.days,
        },
        {
            label: timeLeft.hours === 1 ? "Hora" : "Horas",
            value: timeLeft.hours,
        },
        {
            label: timeLeft.minutes === 1 ? "Minuto" : "Minutos",
            value: timeLeft.minutes,
        },
        {
            label: timeLeft.seconds === 1 ? "Segundo" : "Segundos",
            value: timeLeft.seconds,
        },
    ]

    return (
        <Box sx={{
            display: "flex",
            width: "100%",
        }}>
            {tiemops.map((item, index) => (
                <Box key={index} sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    fontFamily: "Boston Angel Medium",
                    width: "100%",
                }}>
                    <Box sx={{
                        fontSize: "48px",
                        fontWeight: "bold",
                    }}>{item.value}</Box>
                    <Box sx={{
                        fontSize: "16px",
                    }}>{item.label}</Box>
                </Box>
            ))}
        </Box>
    );

};

export default Countdown;