import { Box } from "@mui/material";

export default function Line1(props) {
    return (
        <Box sx={{
            ...props.sx,
            height: "2px",
            width: "100%",
            backgroundColor: "#000000",
        }} />
    )
}