import { Button } from "@mui/material";

export default function Button1(props) {
    return (
        <Button
            onClick={props.onClick}
            sx={{
                fontFamily: "kurale",
                fontSize: "12px",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: "28px",
                paddingY: "10px",
                color: "#ffffff",
                width: "200px",
                maxWidth: "100%",
                zIndex: 1,
                ...props.sx,
            }}
            href={props.href}
            target={props.target}
        >
            {props.children}
        </Button>
    )
}