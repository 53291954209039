import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Tarjeta from './pages/Tarjeta';
import Cuenta from './pages/cuenta/Cuenta';
import Mesas from './pages/Mesas';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Tarjeta />} />
        <Route path='/falta-poco' element={<Cuenta />} />
        <Route path='/mesas' element={<Mesas />} />
      </Routes>
    </Router>
  );
}

export default App;