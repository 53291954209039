import { Box } from "@mui/system";
import TypographyH2 from "../partials/TypographyH2";
import Line1 from "../partials/Line1";
import VerticalLine from "../partials/VerticalLine";
import TypographyTexto from "../partials/TypographyTexto";
import Button1 from "../partials/Button1";

import Foto from "./foto.jpg"
import { useState } from "react";
import Modal1 from "../partials/Modal1";
import TextField1 from "../partials/Textfield1";
import useFetch, { host } from "../../utils/Fetch";


export default function Celebracion({ onImageLoaded }) {
    const [nombreCompleto, setNombreCompleto] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalVerUbicacion, setModalVerUbicacion] = useState(false);
    const [modalConfimado, setModalConfirmado] = useState(false);

    const { postFetch } = useFetch();


    const eventDetails = {
        title: "Celebración de Fiama y Santiago",
        description: "Celebración de Fiama y Santiago",
        location: "La Quinta Eventos de la Villa, RN 158, km 163, Córdoba, Argentina",
        startTime: "20241130T223000Z",
        endTime: "20241201T080000Z"
    };

    function handleConfirmar() {
        postFetch(`${host}asistencia-celebracion/`, {
            nombre_completo: nombreCompleto,
        }).then(() => {
            setModalConfirmado(true);
        })
    }

    const getGoogleCalendarUrl = () => {
        const baseUrl = "https://calendar.google.com/calendar/render";
        const params = new URLSearchParams({
            action: "TEMPLATE",
            text: eventDetails.title,
            details: eventDetails.description,
            location: eventDetails.location,
            dates: `${eventDetails.startTime}/${eventDetails.endTime}`
        });
        return `${baseUrl}?${params.toString()}`;
    };


    return (
        <Box sx={{
            display: "flex",
            marginY: "20px",
            gap: "10px",
            flexDirection: "row",
            width: "100%",
        }}>
            <img src={Foto} alt="foto" style={{
                width: "50%",
                objectFit: "cover",
                flexShrink: 0,
            }}
                onLoad={onImageLoaded}
            />
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                gap: "15px",
                alignItems: "center",
                width: "calc(50% - 10px)",
            }}>
                <TypographyH2 sx={{
                    textAlign: "center",
                }}>
                    Celebración
                </TypographyH2>
                <Box sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "3px",
                }}>
                    <Line1 />
                    <Line1 />
                </Box>
                <TypographyTexto>
                    Lugar:
                    Salón La Quinta Eventos
                </TypographyTexto>
                <TypographyTexto>
                    Hora:
                    19.30hs
                </TypographyTexto>
                <TypographyTexto>
                    Dirección:
                    RN 158, km 163
                </TypographyTexto>
                <Button1 onClick={() => setModalOpen(true)}
                >
                    Confirmar Asistencia
                </Button1>
                <Button1
                    href={getGoogleCalendarUrl()}
                    target="_blank">

                    Agendar
                </Button1>
                <Button1
                    onClick={() => setModalVerUbicacion(true)}
                >
                    Ver Ubicación
                </Button1>
            </Box>
            <VerticalLine />
            <Modal1
                open={modalOpen}
                onClose={() => setModalOpen(false)}
            >
                <TypographyH2>
                    Confirmar Asistencia
                </TypographyH2>
                <TextField1
                    label="Nombre Completo"
                    value={nombreCompleto}
                    onChange={(e) => setNombreCompleto(e.target.value)}
                />
                <Button1
                    onClick={() => {
                        handleConfirmar();
                        setNombreCompleto("");
                        setModalOpen(false);
                    }}
                >
                    Confirmar
                </Button1>
            </Modal1>
            <Modal1
                open={modalVerUbicacion}
                onClose={() => setModalVerUbicacion(false)}
            >
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53885.86828721631!2d-63.36999893188478!3d-32.42272674034575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95cc44a3b2dc90bf%3A0x513760091085346b!2sla%20quinta%20eventos%20de%20la%20villa!5e0!3m2!1ses!2sar!4v1728440526044!5m2!1ses!2sar"

                    width="100%"
                    style={{
                        border: 0,
                    }}
                    height="300px"
                    allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </Modal1>
            <Modal1
                open={modalConfimado}
                onClose={() => setModalConfirmado(false)}
            >
                <TypographyH2>
                    Confirmación Enviada
                </TypographyH2>
            </Modal1>
        </Box>
    )
}