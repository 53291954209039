import { Box } from '@mui/material';
import Image1 from '../../assets/cuenta1.jpg';
import Image2 from '../../assets/cuenta2.jpg';
import Image3 from '../../assets/cuenta3.jpg';
import Countdown from './Countdown'; // Importa el componente de cuenta regresiva
import { useEffect, useState } from 'react';

export default function Cuenta() {
    const imagenes = [Image1, Image2, Image3];
    const [posX, setPosX] = useState(0);
    const [sentido, setSentido] = useState(1);

    useEffect(() => {
        const interval = setTimeout(() => {
            let newSentido = sentido;
            let newPos = posX;

            if (posX >= 2) {
                newSentido = -1;
            }

            if (posX <= 0) {
                newSentido = 1;
            }

            newPos += newSentido;

            setPosX(newPos);
            setSentido(newSentido);
        }, 3000);

        return () => clearTimeout(interval);
    }, [posX, sentido]);

    return (
        <Box sx={{
            height: window.innerHeight,
            width: window.innerWidth,
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
        }}>
            <Box sx={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}>
                <Box sx={{
                    position: "absolute",
                    top: 0,
                    display: "flex",
                    transition: "all 2s ease",
                    height: "100%",
                    left: `${-posX * 100}%`,
                }}>
                    {imagenes.map((imagen, index) => (
                        <img key={index} src={imagen} alt="imagen" style={{
                            width: window.innerWidth,
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top"
                        }} />
                    ))}
                </Box>
            </Box>
            <Box sx={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                height: "20%",
                background: "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                fontSize: "24px",
            }}>
                <Countdown targetDate={new Date('2024-11-30T20:30:00')} />
            </Box>
        </Box>
    )
}