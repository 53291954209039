import { Box } from "@mui/material";
import TypographyH2 from "../partials/TypographyH2";
import Line1 from "../partials/Line1";
import TypographyTexto from "../partials/TypographyTexto";
import InstagramIcon from '@mui/icons-material/Instagram';

import Logo from "../../assets/logo.png";

export default function Footer({ onImageLoaded }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            marginTop: "40px",
            paddingBottom: "40px",
        }}>
            <TypographyH2 sx={{
                textAlign: "center"
            }}>
                Crear recuerdos juntos
            </TypographyH2>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
            }}>
                <Line1 />
                <Line1 />
            </Box>
            <TypographyTexto sx={{
                fontSize: "18px",
                textWrap: "nowrap",
                textAlign: "center"
            }}>
                No olvides etiquetar a los novios en las redes!
            </TypographyTexto>
            <InstagramIcon sx={{
                fontSize: "50px",
            }} />
            <TypographyTexto sx={{
                fontSize: "22px",
                textAlign: "center"
            }}>
                @fiamamignola
            </TypographyTexto>
            <TypographyTexto sx={{
                fontSize: "22px",
                textAlign: "center"
            }}>
                @santivirgolin
            </TypographyTexto>
            <img src={Logo} style={{
                width: "200px",
                height: "auto",
            }}
                onLoad={onImageLoaded}
            />
        </Box>
    )
}