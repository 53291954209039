import { Box, Typography } from "@mui/material";
import Image from './image.png'
import Line1 from "../partials/Line1";
import TypographyTexto from "../partials/TypographyTexto";

export default function Header() {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            paddingTop: "30px",
            alignItems: "center",
            width: "100%",
            position: "fixed",
            paddingX: "10px",
            backgroundColor: "#EBE9E7",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
            boxSizing: "border-box",
            overflow: "hidden",
            borderBottom: "1px solid #000000",
            zIndex: 999,
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                width: "100%",
                marginBottom: "10px",
            }}>
                <TypographyTexto sx={{
                    position: "absolute",
                    left: "10px",
                }}>
                    Edición Especial
                </TypographyTexto>
                <img src={Image} alt="logo" style={{
                    width: "130px",
                    objectFit: "contain"
                }} />
                <TypographyTexto sx={{
                    position: "absolute",
                    right: "10px",
                }}>
                    Villa María
                </TypographyTexto>
            </Box>

        </Box>
    )
}