import { Typography } from "@mui/material";

export default function TypographyTexto(props) {
    return (
        <Typography sx={{
            fontFamily: "kurale",
            textAlign: "center",
            whiteSpace: "pre-line",
            ...props.sx,
        }}>
            {props.children}
        </Typography>
    )
}