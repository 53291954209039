import { Box, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import TypographyH2 from "../partials/TypographyH2";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function Carrousel({ onImageLoaded }) {
    const [posicionActual, setPosicionActual] = useState(0);
    const [carrouselWidth, setCarrouselWidth] = useState(0);

    useEffect(() => {
        if (carrouselRef?.current) {
            setCarrouselWidth(carrouselRef.current.offsetWidth);
        }
        const interval = setInterval(() => {
            setPosicionActual(prevPosicionActual =>
                prevPosicionActual === images.length - 1 ? 0 : prevPosicionActual + 1
            );
        }, 3000);

        return () => clearInterval(interval);
    }, [])


    function importAll(r) {
        return r.keys().map(r);
    }

    const images = importAll(require.context('./sesion', false, /\.(png|jpe?g|svg)$/));
    const carrouselRef = useRef(null);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: "40px",
            gap: "20px",
        }}
            ref={carrouselRef}
        >
            <TypographyH2 sx={{
                textAlign: "center",
            }}>
                Imágenes que hablan de su amor
            </TypographyH2>
            <Box sx={{
                position: "relative",
                height: `${carrouselWidth}px`,
            }}>
                <Box sx={{
                    display: "flex",
                    position: "absolute",
                    top: 0,
                    left: `-${posicionActual * (carrouselWidth + 10)}px`,
                    transition: "left 0.5s",
                    gap: "10px",
                }}
                >
                    {images.map((image, index) => {
                        return (
                            <Box key={index} sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <img key={index} src={image} alt="foto" style={{
                                    width: `${carrouselWidth}px`,
                                    maxHeight: `${carrouselWidth}px`,
                                    objectFit: "contain",
                                }}

                                    onLoad={onImageLoaded}
                                />
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                gap: "150px",
                justifyContent: "center",
            }}>
                <IconButton
                    onClick={() => {
                        setPosicionActual(posicionActual - 1);
                    }}
                    sx={{
                        visibility: posicionActual === 0 ? "hidden" : "visible",
                    }}
                >
                    <ArrowBackIosNewIcon />
                </IconButton>

                <IconButton
                    onClick={() => {
                        setPosicionActual(posicionActual + 1);
                    }}
                    sx={{
                        visibility: posicionActual === images.length - 1 ? "hidden" : "visible",
                    }}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Box >
    )
}