import { Typography } from "@mui/material";

export default function TypographyH2(props) {
    return (
        <Typography sx={{

            fontFamily: "Boston Angel Medium",
            fontSize: "24px",
            fontWeight: "bold",
            ...props.sx,
        }}>
            {props.children}
        </Typography>
    )
}