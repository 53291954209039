import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import TextField1 from "../components/partials/Textfield1";
import TypographyH2 from "../components/partials/TypographyH2";
import TypographyTexto from "../components/partials/TypographyTexto";
import { useEffect, useState } from "react";
import useFetch, { host } from "../utils/Fetch";
import Fondo from '../assets/fondo.png';

export default function Mesas() {
    const [invitados, setInvitados] = useState([]);
    const [invitado, setInvitado] = useState(null);
    const [mesas, setMesas] = useState([]);
    const [mesa, setMesa] = useState(null);

    const { getFetch } = useFetch();

    useEffect(() => {
        getFetch(`${host}mesas/`).then((data) => {
            console.log(data.invitados);

            setInvitados(data.invitados.sort((a, b) => a.mesa - b.mesa).sort((a, b) => a.nombre - b.nombre));
            let newMesas = [];

            data.invitados.forEach((inv) => {
                if (inv.mesa === 0) return;
                if (!newMesas.includes(inv.mesa)) {
                    newMesas.push(inv.mesa);
                }
            }
            );

            setMesas(newMesas);
        })
    }, []);

    return (
        <Box sx={{
            height: window.innerHeight,
            width: window.innerWidth,
            padding: "30px",
            boxSizing: "border-box",
            display: "flex",
            justifyContent: "center",
            overflowY: "auto",
        }}>
            <Box sx={{
                height: "100vh",
                width: "100%",
                position: "fixed",
                top: 0,
                left: 0,
                zIndex: -1,
            }}>
                <img src={Fondo} style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                }} />
            </Box>
            <Box sx={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "400px",
                gap: "30px",
            }}>
                {/* <Box sx={{
                    border: "1px solid black",
                    display: "flex",
                    height: "300px",
                    width: "100%",
                    boxSizing: "border-box",
                    padding: "20px",
                    flexShrink: 0,
                }}>
                    <Box sx={{
                        width: "20%",
                        height: "100%",
                        boxShadow: "0px 0px 3px 0px black",
                        borderRadius: "8px",
                    }} />
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        flexGrow: 1,
                        gap: "20px",
                    }}>
                        <Box sx={{
                            boxShadow: "0px 0px 8px 0px black",
                            borderRadius: "20px",
                            paddingX: "40px",
                            paddingY: "3px",
                            backgroundColor: mesa === 0 ? "#B8B0A2" : "transparent",
                        }}
                            onClick={() => {
                                setInvitado(null);
                                setMesa(0)
                            }}
                        >
                            <TypographyTexto>Principal</TypographyTexto>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            flexGrow: 1,
                        }}>
                            {mesas.map((m) => (
                                <Box key={m} sx={{
                                    width: "30%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}

                                >
                                    <Box sx={{
                                        // border: "1px solid black",
                                        backgroundColor: mesa === m ? "B8B0A2" : "transparent",
                                        borderRadius: "20px",
                                        width: "35px",
                                        height: "35px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        boxShadow: "0px 0px 5px 0px black",
                                    }}
                                        onClick={() => {
                                            setInvitado(null);
                                            setMesa(m)
                                        }}
                                    >
                                        <TypographyTexto>{m}</TypographyTexto>
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                </Box> */}
                <TypographyH2>¿Dónde me siento?</TypographyH2>
                <Autocomplete
                    options={invitados}
                    getOptionLabel={(option) => option.nombre + " " + option.apellido}
                    onChange={(event, value) => {
                        setMesa(null);
                        setInvitado(value);
                    }}
                    value={invitado}
                    fullWidth
                    renderInput={(params) => <TextField {...params} label="Nombre"
                        variant="standard"
                        sx={{
                            width: "100%",
                            fontFamily: "kurale",
                            "& .MuiInputBase-input": {
                                color: "black", // Color del texto
                                fontFamily: "kurale",
                            },
                            "& .MuiInputLabel-root": {
                                color: "black", // Color del label inicialmente
                                fontFamily: "kurale",
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: "black", // Color del label cuando está en focus
                                fontFamily: "kurale",
                            },
                            "& .MuiInput-underline:before": {
                                borderBottomColor: "black", // Color del borde antes del focus
                                fontFamily: "kurale",
                            },
                            "& .MuiInput-underline:after": {
                                borderBottomColor: "transparent", // Elimina el borde cuando está en focus
                                fontFamily: "kurale",
                            },
                            "& .MuiInput-underline:hover:before": {
                                borderBottomColor: "black", // Color del borde al hacer hover
                                fontFamily: "kurale",
                            },
                        }}
                    />}
                />
                {invitado && (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "30px",
                    }}>
                        <TypographyH2>Mesa {invitado.mesa === 0 ? "Principal" : invitado.mesa}</TypographyH2>
                        <TypographyH2>
                            ¿Con quién me siento?
                        </TypographyH2>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                            }}>
                            {invitados.filter((inv) => inv.mesa === invitado.mesa).map((inv) => (
                                <TypographyTexto key={inv.id}
                                    sx={{
                                        fontWeight: inv.nombre + inv.apellido === invitado.nombre + invitado.apellido ? "bold" : "normal",
                                    }}
                                >{inv.nombre} {inv.apellido}</TypographyTexto>
                            ))}
                        </Box>
                    </Box>
                )}
                {mesa !== null && (
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "30px",
                    }}>
                        <TypographyH2>Mesa {mesa === 0 ? "Principal" : mesa}</TypographyH2>
                        <TypographyH2>
                            ¿Quién se sienta en esta mesa?
                        </TypographyH2>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "10px",
                            }}>
                            {invitados.filter((inv) => inv.mesa === mesa).map((inv) => (
                                <TypographyTexto key={inv.id}
                                >{inv.nombre} {inv.apellido}</TypographyTexto>
                            ))}
                        </Box>
                    </Box>
                )}
            </Box>

        </Box>
    )
}