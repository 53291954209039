import { TextField } from "@mui/material";

export default function TextField1(props) {
    return (
        <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            label={props.label}
            fullWidth
            sx={{
                "& .MuiInputBase-input": {
                    color: "grey", // Color del texto
                },
                "& .MuiInputLabel-root": {
                    color: "grey", // Color del label inicialmente
                },
                "& .MuiInputLabel-root.Mui-focused": {
                    color: "grey", // Color del label cuando está en focus
                },
                "& .MuiInput-underline:before": {
                    borderBottomColor: "grey", // Color del borde antes del focus
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: "transparent", // Elimina el borde cuando está en focus
                },
                "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "grey", // Color del borde al hacer hover
                },
            }}
            InputProps={{
                style: {
                    caretColor: "grey", // Color del cursor
                },
            }}
        />
    );
}