import { Box, Modal } from "@mui/material";
import Fondo from "../../assets/fondo.png";

export default function Modal1(props) {
    const { open, onClose, children } = props;

    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus
        >
            <Box sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "70vw",
                maxWidth: "400px",
                backgroundImage: `url(${Fondo})`,
                backgroundSize: "cover",
                border: "1px solid #000000",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxShadow: 24,
                gap: 2,
                p: 4,
            }}>
                {children}
            </Box>
        </Modal>
    );
}