import { Box } from "@mui/material";

export default function VerticalLine(props) {
    return (
        <Box sx={{
            ...props.sx,
            width: "6px",
            height: "100%",
            backgroundColor: "#000000",
        }} />
    )
}