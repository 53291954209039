import { Box } from "@mui/material";
import TypographyH2 from "../partials/TypographyH2";
import Line1 from "../partials/Line1";
import TypographyTexto from "../partials/TypographyTexto";
import Button1 from "../partials/Button1";
import { useState } from "react";
import Modal1 from "../partials/Modal1";

export default function Regalos() {
    const [modalBancarios, setModalBancarios] = useState(false);

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "40px",
        }}>
            <TypographyH2 sx={{
                fontSize: "2rem",
                textAlign: "center",
            }}>
                Regalos
            </TypographyH2>
            <Line1 />
            <Line1 sx={{

                marginTop: "3px",
            }} />
            <TypographyTexto sx={{
                // textAlign: "center",
                marginTop: "20px",
            }}>
                Su presencia en este día especial es el mejor regalo para los novios. Sin embargo, si desean hacerles un obsequio adicional,  en lugar de regalos tradicionales, invitan a contribuir con su soñada luna de miel.
            </TypographyTexto>
            <Button1 sx={{
                marginTop: "20px",
            }}
                onClick={() => setModalBancarios(true)}
            >
                Datos Bancarios
            </Button1>
            <Modal1
                open={modalBancarios}
                onClose={() => setModalBancarios(false)}
                title="Datos Bancarios"
            >
                <TypographyH2>
                    Datos Bancarios
                </TypographyH2>
                <TypographyTexto>
                    Titular de la cuenta de Mercado Pago: Fiama Mignola
                </TypographyTexto>
                <TypographyTexto>
                    CVU:0000003100053204637772

                </TypographyTexto>
                <TypographyTexto>
                    Alias: BODA.FIAMAYSANTI
                </TypographyTexto>
                <TypographyTexto>
                    CUIT/CUIL: 27400360842
                </TypographyTexto>
            </Modal1>
        </Box>
    )
}