import { Box, Typography } from "@mui/material";
import Image from './image.jpg'
import Line1 from "../partials/Line1";
import TypographyH2 from "../partials/TypographyH2";

export default function Portada({ onImageLoaded }) {


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
        }}>
            <Typography sx={{
                color: "#000000",
                fontFamily: "Engravers Old English BT",
                fontSize: "40px",
                marginTop: "10px",
                textWrap: "nowrap",
            }}>
                Diario de una Boda
            </Typography>
            <Line1 sx={{
                marginTop: "10px",
            }} />
            <Box sx={{
                height: "5px",
                width: "100%",
                backgroundColor: "#000000",
                marginTop: "3px",
            }} />
            <Typography sx={{
                fontFamily: "Boston Angel",
                fontSize: "22px",
                textWrap: "nowrap",
                marginY: "10px",
            }}>
                Fiama & Santi darán el ”Si Quiero”
            </Typography>
            <Line1 />
            <img src={Image} alt="portada" style={{
                width: "100%",
                objectFit: "contain",
                marginTop: "10px",
            }}
                onLoad={onImageLoaded}
            />
            <Line1 sx={{
                marginTop: "10px",
            }} />
            <TypographyH2>
                ¡La boda más esperada!
            </TypographyH2>
            <Line1 sx={{
                marginTop: "10px",
            }} />
            <Line1 sx={{
                marginTop: "2px",
            }} />
            <Typography sx={{
                fontFamily: "kurale",
                textAlign: "justify",
            }}>
                Su historia comenzó de una forma inesperada,  en el famoso “oscurito” de Rouge. Y varias citas después confirmaron que los opuestos se atraen. Pasaron 7 años desde aquel primer encuentro. Siete años de amor, de risas, de aventuras y viajes que los han llevado a este día tan especial en el que celebraremos su unión. Te invitamos a ser testigo de esta hermosa historia de amor.
            </Typography>
        </Box>
    )
}