import { Slide, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import LeftLogo from "../../assets/left_logo.png"
import RightLogo from "../../assets/right_logo.png"
import Logo from "../../assets/logo.png"
import Button1 from "../partials/Button1";
import TypographyH2 from "../partials/TypographyH2";
import Fondo from "../../assets/fondo.png";

import FavoriteIcon from '@mui/icons-material/Favorite';

export default function OpenEffect(props) {
    const { audioRef, isReady } = props;

    const [open, setOpen] = useState(true);
    const [escala, setEscala] = useState(1);
    const [ingresar, setIngresar] = useState(true);


    let tiempo = 1000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (escala === 1) {
                setEscala(1.2);
            } else {
                setEscala(1);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [escala]);

    useEffect(() => {
        setTimeout(() => {
            if (isReady()) {
                setOpen(false);
            }
        }, 5000);
    }
        , []);

    return (
        <Box sx={{
            display: "flex",
            position: "fixed",
            height: "0px",
            backgroundColor: "#EBE9E7",
            zIndex: 9999,
        }}
        >
            <Slide
                direction="down"
                in={ingresar}
                timeout={1000}
                appear={false}
            >
                <Box sx={{
                    height: "100vh",
                    width: "100vw",
                    position: "absolute",
                    backgroundColor: "#EBE9E7",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "40vh",
                    gap: "40px",
                }}>
                    <TypographyH2 sx={{
                        fontWeight: "",
                    }}>
                        Edición Especial Fiama y Santi
                    </TypographyH2>
                    {/* <FavoriteIcon sx={{
                    fontSize: "80px",
                    color: "rgba(0, 0, 0, 0.5)",
                }} /> */}
                    <Button1 onClick={() => {
                        audioRef.current.play()
                        setIngresar(false)
                    }}>
                        Ingresar
                    </Button1>
                </Box>
            </Slide>
            <Slide
                direction="right"
                in={open}
                timeout={tiempo}
                appear={false}
            >
                <Box sx={{
                    height: "100vh",
                    width: "50vw",
                    backgroundColor: "#EBE9E7",
                    position: "relative",
                    overflow: open ? "visible" : "hidden",
                    zIndex: 9999,
                }}>
                    <img src={Logo} alt="logo" style={{
                        height: "150px",
                        objectFit: "contain",
                        position: "absolute",
                        top: "30%",
                        left: "calc(100% - 75px)",
                        transform: `scale(${escala})`,
                        transition: "transform 1s",
                    }}
                    />
                </Box>
            </Slide>
            <Slide
                direction="left"
                in={open}
                timeout={tiempo}
                appear={false}
            >
                <Box sx={{
                    height: "100vh",
                    width: "50vw",
                    backgroundColor: "#EBE9E7",
                    position: "relative",
                    overflow: "hidden",
                    zIndex: 9998,
                }}>
                    <img src={RightLogo} alt="logo" style={{
                        height: "150px",
                        objectFit: "contain",
                        position: "absolute",
                        top: "30%",
                        right: "calc(100% - 75px)",
                        transform: `scale(${escala})`,
                        transition: "transform 1s",
                        visibility: open ? "hidden" : "visible",
                    }}
                    />
                </Box>
            </Slide>

        </Box>
    )
}