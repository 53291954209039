import { useState } from "react";
import Button1 from "../partials/Button1";
import Modal1 from "../partials/Modal1";
import TypographyH2 from "../partials/TypographyH2";
import TypographyTexto from "../partials/TypographyTexto";
import LogoMusica from "./musica.png"
import LogoDressCode from "./dresscode.png"

import { Box, List, ListItem, TextField } from "@mui/material";
import TextField1 from "../partials/Textfield1";
import useFetch, { host } from "../../utils/Fetch";

export default function Detalles({ onImageLoaded }) {
    const [modalMusica, setModalMusica] = useState(false);
    const [modalDressCode, setModalDressCode] = useState(false);
    const [modalTips, setModalTips] = useState(false);
    const [musicaNombre, setMusicaNombre] = useState("");
    const [musicaCancion, setMusicaCancion] = useState("");
    const [modalConfirmado, setModalConfirmado] = useState(false);

    const { postFetch } = useFetch();

    function handleConfirmar() {
        postFetch(`${host}recomendacion-musica/`, {
            nombre_completo: musicaNombre,
            nombre_o_artista: musicaCancion,
        }).then(() => {
            setModalConfirmado(true);
        })
    }

    const detalles = [
        {
            nombre: "Música",
            descripcion: "Cuál es la canción que no debe faltar en la playlist de la fiesta",
            boton: "Recomendar",
            onClick: () => setModalMusica(true)
        },
        {
            nombre: "Dress code",
            descripcion: "Una orientación para tu vestuario",
            boton: "Ver Más",
            onClick: () => setModalDressCode(true)
        },
        {
            nombre: "Tips y notas",
            descripcion: "Información adicional para tener en cuenta",
            boton: "Ver Más",
            onClick: () => setModalTips(true)
        }
    ]

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "40px",
            paddingX: "40px",
            gap: "40px",
        }}>
            {detalles.map(detalle => (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "10px"
                }}>
                    <TypographyH2>
                        {detalle.nombre}
                    </TypographyH2>
                    <TypographyTexto>
                        {detalle.descripcion}
                    </TypographyTexto>
                    <Button1
                        onClick={detalle.onClick}
                    >
                        {detalle.boton}
                    </Button1>
                </Box>
            ))}
            <Modal1
                open={modalMusica}
                onClose={() => setModalMusica(false)}
                titulo="Música"
                contenido="Canciones que no pueden faltar en la playlist de la fiesta"
            >
                <TypographyH2>
                    Sugerir canción
                </TypographyH2>
                <img src={LogoMusica} alt="musica" style={{
                    width: "100px",
                    objectFit: "contain"
                }}
                    onLoad={onImageLoaded}
                />
                <TextField1
                    label="Tu nombre..."
                    value={musicaCancion}
                    onChange={(e) => setMusicaCancion(e.target.value)}
                />

                <TextField1
                    label="Nombre de la canción o artista..."
                    value={musicaNombre}
                    onChange={(e) => setMusicaNombre(e.target.value)}
                />
                <Button1 onClick={() => {
                    handleConfirmar();
                    setMusicaNombre("");
                    setMusicaCancion("");
                    setModalMusica(false);
                }}>
                    Enviar
                </Button1>
            </Modal1>
            <Modal1
                open={modalDressCode}
                onClose={() => setModalDressCode(false)}
                titulo="Dress Code"
                contenido="Vestuario sugerido"
            >
                <TypographyH2>
                    Dress Code
                </TypographyH2>
                <img src={LogoDressCode} alt="dresscode" style={{
                    width: "140px",
                    objectFit: "contain"
                }}
                    onLoad={onImageLoaded}
                />
                <TypographyTexto sx={{
                    fontSize: "20px"
                }}>
                    De gala
                </TypographyTexto>
                <TypographyTexto>
                    Evitar colores: violeta / magenta
                </TypographyTexto>
            </Modal1>
            <Modal1
                open={modalTips}
                onClose={() => setModalTips(false)}
                titulo="Tips y notas"
                contenido="Información adicional"
            >
                <TypographyH2>
                    Tips y notas
                </TypographyH2>
                <ul>
                    <li>
                        <TypographyTexto sx={{
                            textAlign: "left"
                        }}>
                            Por favor ser puntuales.
                        </TypographyTexto>
                    </li>
                    <li>
                        <TypographyTexto sx={{
                            textAlign: "left"
                        }}>
                            Queremos que disfrutes de esta fiesta al máximo, por eso decidimos que sea un evento solo para adultos.
                        </TypographyTexto>
                    </li>
                    <li>
                        <TypographyTexto sx={{
                            textAlign: "left"
                        }}>
                            Confirmar asistencia hasta el 15/11.
                        </TypographyTexto>
                    </li>
                    <li>
                        <TypographyTexto sx={{
                            textAlign: "left"
                        }}>
                            En el caso de tener alguna restricción alimenticia comunicarse con los novios.
                        </TypographyTexto>
                    </li>
                </ul>
            </Modal1>
            <Modal1
                open={modalConfirmado}
                onClose={() => setModalConfirmado(false)}
                titulo="Recomendación enviada"
                contenido="Gracias por tu recomendación"
            >
                <TypographyH2>
                    Canción Enviada
                </TypographyH2>
            </Modal1>
        </Box >
    )
}