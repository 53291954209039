import { Box, Typography } from "@mui/material";
import Line1 from "../partials/Line1";
import TypographyTexto from "../partials/TypographyTexto";
import TypographyH2 from "../partials/TypographyH2";
import VerticalLine from "../partials/VerticalLine";
import { useState } from "react";

export default function Fecha() {
    let fecha = new Date("2024-11-30T18:00:00");

    const [dias, setDias] = useState(Math.floor((fecha - new Date()) / (1000 * 60 * 60 * 24)));
    const [horas, setHoras] = useState(Math.floor((fecha - new Date()) / (1000 * 60 * 60) % 24));
    const [minutos, setMinutos] = useState(Math.floor((fecha - new Date()) / (1000 * 60) % 60));
    const [segundos, setSegundos] = useState(Math.floor((fecha - new Date()) / 1000 % 60));

    setInterval(() => {
        setDias(Math.floor((fecha - new Date()) / (1000 * 60 * 60 * 24)));
        setHoras(Math.floor((fecha - new Date()) / (1000 * 60 * 60) % 24));
        setMinutos(Math.floor((fecha - new Date()) / (1000 * 60) % 60));
        setSegundos(Math.floor((fecha - new Date()) / 1000 % 60));
    }
        , 1000);


    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
        }}>
            <Line1 />
            <Box sx={{
                display: "flex",
                marginY: "20px",
                gap: "10px",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                }}>
                    <TypographyH2 sx={{
                    }}>
                        La Fecha
                    </TypographyH2>
                    <Line1 />
                    <Line1 sx={{
                        marginTop: "2px",
                    }} />
                    <TypographyTexto sx={{
                        textAlign: "left"
                    }}>
                        Desde la navidad del 2023, que anunciaron su compromiso. Su familia y amigos han estado expectantes a la fecha del gran evento.
                    </TypographyTexto>
                </Box>
                <VerticalLine />
                <Box sx={{
                    width: "200px",
                    height: "200px",
                    flexShrink: 0,
                    border: "1px solid #000000",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}>
                    <TypographyH2 sx={{
                        textAlign: "center",
                        fontSize: "40px",
                        lineHeight: "0.4"
                    }}>
                        SÁBADO
                    </TypographyH2>
                    <TypographyH2 sx={{
                        textAlign: "center",
                        fontSize: "58px",
                        margin: "0"
                    }}>
                        30
                    </TypographyH2>
                    <TypographyH2 sx={{
                        textAlign: "center",
                        fontSize: "30px",
                        lineHeight: "0.4"
                    }}>
                        NOVIEMBRE
                    </TypographyH2>
                </Box>
            </Box>
            <Line1 />
            <Box sx={{
                border: "1px solid #000000",
                width: "100%",
                height: "100px",
                marginTop: "20px",
                display: "flex",
                justifyContent: "space-around",
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        {dias}
                    </TypographyH2>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        DÍAS
                    </TypographyH2>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        {horas}
                    </TypographyH2>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        HORAS
                    </TypographyH2>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        {minutos}
                    </TypographyH2>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        MIN
                    </TypographyH2>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                }}>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        {segundos}
                    </TypographyH2>
                    <TypographyH2 sx={{
                        color: "#000",
                    }}>
                        SEG
                    </TypographyH2>
                </Box>
            </Box>
        </Box >
    )
}