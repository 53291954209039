import { Box } from "@mui/material";
import TypographyH2 from "../partials/TypographyH2";
import Line1 from "../partials/Line1";
import TypographyTexto from "../partials/TypographyTexto";
import Foto from "./foto.jpg"

export default function Invitados({ onImageLoaded }) {
    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
            textAlign: "center",
        }}>
            <TypographyH2>
                Invitados destacados
            </TypographyH2>
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
            }}>
                <Line1 />
                <Line1 />
            </Box>
            <Box>
                <TypographyH2>
                    Damas de honor
                </TypographyH2>
                <TypographyTexto>
                    Ana Laura Corradini{"\n"}
                    Jimena Layún{"\n"}
                    María Rita Giai{"\n"}
                    Stefania Tita
                </TypographyTexto>
            </Box>
            <Box>
                <TypographyH2>
                    Best Men
                </TypographyH2>
                <TypographyTexto>
                    Ignacio Pereyra{"\n"}
                    Matias Cerutti{"\n"}
                    Nicolás Destefanis{"\n"}
                    Luciano Virgolin
                </TypographyTexto>
            </Box>
            <Box>
                <TypographyH2>
                    Madrinas
                </TypographyH2>
                <TypographyTexto>
                    Mariana Zayas{"\n"}
                    Lorena Zablocki
                </TypographyTexto>
            </Box>
            <img src={Foto} style={{
                width: "100%",
                marginTop: "10px"
            }}
                onLoad={onImageLoaded}
            />
            <Box sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "3px",
            }}>
                <Line1 />
                <Line1 />
            </Box>
            <TypographyTexto>
                Hagamos que sea una fiesta inolvidable, a continuación se mencionan algunos detalles a tener en cuenta...
            </TypographyTexto>
        </Box>
    )
}